.appContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
}

.appWrapper {
    max-width: 640px;
    width: 100%;
}