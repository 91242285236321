* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}